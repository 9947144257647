import * as Dialog from '@radix-ui/react-dialog';
import { AsProps, CSSProps, keyframes, styled } from '@neui/core';
import { FC, PropsWithChildren } from 'react';
import { interaction_arrows_chevronLeft } from '@neui/styleguide-commerzbank';

import { IconLink } from '@components/neui-components/atoms/IconLink';

import { useComponentId } from '../utils/id';

export type LayerSizes = '50' | '75' | 'menu';
export type LayerPosition = 'right' | 'left';

export type LayerProps = AsProps &
  CSSProps &
  Dialog.DialogProps &
  PropsWithChildren<{
    size?: LayerSizes;
    position?: LayerPosition;
    closeLabel?: string;
    closeLabelId?: string;
    additionalElement?: JSX.Element | string;
  }>;

const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 0.75 },
});

const fadeOut = keyframes({
  '0%': { opacity: 0.75 },
  '100%': { opacity: 0 },
});

const StyledRoot = styled(Dialog.Root, {
  animationFillMode: 'forwards !important',
});

const StyledOverlay = styled(Dialog.Overlay, {
  $$darkAccent: '#00333d',

  backgroundColor: '$$darkAccent',
  height: '100%',
  opacity: 0.75,
  mixBlendMode: 'multiply',
  position: 'fixed',
  zIndex: 1337,
  maxWidth: '100%',
  left: 0,
  right: 0,
  top: 0,
  animation: `${fadeIn} 450ms cubic-bezier(.1, 0, 0, 1)`,
  animationFillMode: 'forwards !important',

  '&[data-state="closed"]': {
    animation: `${fadeOut} 450ms cubic-bezier(.1, 0, 0, 1)`,
  },
});

const slideInRight = keyframes({
  '0%': { transform: 'translateX(100%)' },
  '100%': { transform: 'translateX(0)' },
});

const slideOutRight = keyframes({
  '0%': { transform: 'translateX(0)' },
  '100%': { transform: 'translateX(100%)' },
});

const slideInLeft = keyframes({
  '0%': { transform: 'translateX(-100%)' },
  '100%': { transform: 'translateX(0)' },
});

const slideOutLeft = keyframes({
  '0%': { transform: 'translateX(0)' },
  '100%': { transform: 'translateX(-100%)' },
});

const StyledContent = styled(Dialog.Content, {
  animationFillMode: 'forwards !important',
  backgroundColor: '$background-elevated',
  top: 0,
  bottom: 0,

  position: 'fixed',
  zIndex: 1337,
  overflow: 'auto',
  overflowX: 'hidden',

  minHeight: '100vh',
  width: '100%',

  '@media(min-width: 640px)': {
    minWidth: 480,
    width: '50%',
  },

  transform: 'translateX(0)',

  variants: {
    size: {
      menu: {
        '@media(min-width: 640px)': {
          width: '25%',
          minWidth: 375,
          maxWidth: 480,
        },
      },
      '50': {
        '@media(min-width: 640px)': {
          width: '50%',
        },
      },
      '75': {
        '@media(min-width: 640px)': {
          width: '75%',
        },
      },
    },

    position: {
      left: {
        left: 0,
        animation: `${slideInLeft} 450ms cubic-bezier(.1, 0, 0, 1)`,

        '&[data-state="closed"]': {
          animation: `${slideOutLeft} 450ms cubic-bezier(.1, 0, 0, 1)`,
        },
      },
      right: {
        right: 0,
        animation: `${slideInRight} 450ms cubic-bezier(.1, 0, 0, 1)`,

        '&[data-state="closed"]': {
          animation: `${slideOutRight} 450ms cubic-bezier(.1, 0, 0, 1)`,
        },
      },
    },
  },
});

const StyledSection = styled('section', {
  overflowX: 'clip',
  marginY: '$layout-5',
  position: 'relative',
  zIndex: 1,
  maxWidth: 720,
  marginX: 'auto',

  '@media(min-width: 640px)': {
    marginY: '$layout-5',
  },

  '@lg': {
    marginY: 84,
  },

  variants: {
    size: {
      menu: {
        marginY: 0,
      },
      '50': {},
      '75': {},
    },
  },
});

const StyledSectionInner = styled('div', {
  maxWidth: 480,
  paddingX: '$component-7',
  margin: '0 auto',
  width: '100%',

  '@media(min-width: 640px)': {
    maxWidth: 'none',
    margin: '0 auto 0 0',
  },

  '@md': {
    paddingX: '$layout-3',
  },

  '@lg': {
    paddingX: '$layout-7',
  },

  variants: {
    size: {
      menu: {
        '@lg': {
          paddingX: 0,
        },
      },
      '50': {},
      '75': {},
    },
  },
});

const StyledHeader = styled('header', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: '$background-elevated',
  boxShadow: '0 2px 4px -1px rgb(0 0 0 / 36%)',
  padding: '0 $component-8',
  margin: '0 auto',
  height: 64,
  position: 'sticky',
  top: 0,
  zIndex: 2,
  '@lg': {
    height: 88,
  },
});

const StyledClose = styled(Dialog.Close, {});

export const Layer: FC<LayerProps> = ({
  size = 'm' as LayerSizes,
  position = 'right',
  children,
  closeLabel,
  closeLabelId,
  additionalElement,
  ...rest
}) => {
  const closeId = useComponentId('close', closeLabelId);

  return (
    <StyledRoot {...rest}>
      <StyledOverlay />
      <StyledContent position={position} size={size}>
        <StyledHeader>
          <StyledClose asChild>
            <IconLink
              icon={interaction_arrows_chevronLeft}
              iconPosition="left"
              animationDirection="top"
              id={closeId}
            >
              {closeLabel}
            </IconLink>
          </StyledClose>
          {additionalElement}
        </StyledHeader>

        <StyledSection size={size}>
          <StyledSectionInner size={size}>{children}</StyledSectionInner>
        </StyledSection>
      </StyledContent>
    </StyledRoot>
  );
};
